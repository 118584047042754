import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SectionIntro from '../SectionIntro';
import { isMobile } from '../../utils';
import { Button } from '../Button';
import MediaItem from '../MediaItem';

const PartnerLogos = ({ title, logos, introTitle, introSubTitle }) => {
  const mobile = isMobile();
  const logoHeight = mobile ? 60 : 160;
  const logosPerRow = mobile ? 2 : 4;
  const marginBottom = mobile ? 40 : 60;
  const logoLines = Math.ceil(logos.length / logosPerRow);
  const [logosExpanded, setLogosExpanded] = useState(false);
  return (
    <div className="partner-logos">
      {introTitle && introSubTitle && (
        <div className="partner-logos__intro">
          <SectionIntro title={introTitle} subtitle={introSubTitle} />
        </div>
      )}
      <div className="container">
        <div className="partner-logos__inner">
          <h5 className="partner-logos__title">{title}</h5>
          <div
            className="partner-logos__logos"
            style={{
              maxHeight: logosExpanded
                ? logoHeight * logoLines
                : logoHeight * 3,
            }}
          >
            {logos.map((image, i) => (
              <div
                className="partner-logos__logo"
                key={`partner-logos__logo${i}`}
                style={{ marginBottom }}
              >
                <MediaItem
                  asset={image}
                  className="partner-logos__image"
                  imageWrapperStyle={{ maxHeight: logoHeight - marginBottom }}
                  imageStyle={{ objectFit: 'scale-down' }}
                />
              </div>
            ))}
          </div>
          {logoLines > 3 && (
            <div className="partner-logos__show-more">
              <Button onClick={() => setLogosExpanded(!logosExpanded)}>
                {logosExpanded ? 'Show less' : 'Show more'}
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

PartnerLogos.propTypes = {
  title: PropTypes.string,
  logos: PropTypes.array,
  introTitle: PropTypes.string,
  introSubTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

PartnerLogos.defaultProps = {
  introTitle: null,
  introSubTitle: null,
};

export default PartnerLogos;
