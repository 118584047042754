import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/Layout';
import MediaHero from '../../components/MediaHero';
import DigitalTools from '../../components/DigitalTools';
import PartnerLogos from '../../components/PartnerLogos/PartnerLogos';
import DemoBanner from '../../components/DemoBanner';
import SiteMeta from '../../components/SiteMeta';

const EcosystemPage = ({ data }) => {
  const {
    contentfulEcosystemPage: {
      metaTitle,
      metaDescription: { metaDescription },
      heroModule,
      digitalToolsTitle,
      digitalToolsSubheader: { digitalToolsSubheader },
      digitalTools,
      courseProvidersTitle,
      courseProviderIcons,
      contentProvidersTitle,
      featuredContentProviders,
      contentProviders,
      learnCalloutCtaLink,
      learnCalloutCtaText,
      learnCalloutText,
      contentPartnersTitle,
      contentPartnersSubheader,
    },
  } = data;

  return (
    <Layout>
      <SiteMeta title={metaTitle} description={metaDescription} />
      <MediaHero {...heroModule} />
      <DigitalTools
        title={digitalToolsTitle}
        subtitle={digitalToolsSubheader}
        items={digitalTools}
      />
      <PartnerLogos
        introTitle={contentPartnersTitle}
        introSubTitle={contentPartnersSubheader}
        title={courseProvidersTitle}
        logos={courseProviderIcons}
      />
      <PartnerLogos
        title={contentProvidersTitle}
        logos={[...featuredContentProviders, ...contentProviders]}
      />
      <DemoBanner
        ctaText={learnCalloutCtaText}
        ctaLink={learnCalloutCtaLink}
        title={learnCalloutText}
        theme="dark"
      />
    </Layout>
  );
};

export default EcosystemPage;

export const query = graphql`
  query {
    contentfulEcosystemPage {
      metaTitle
      metaDescription {
        metaDescription
      }
      heroModule {
        ...Navigator
      }
      digitalToolsTitle
      digitalToolsSubheader {
        digitalToolsSubheader
      }
      digitalTools {
        id
        url
        digitalToolPartnerIcon {
          file {
            url
            contentType
          }
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyContentfulFluid
          }
        }
      }
      courseProvidersTitle
      courseProviderIcons {
        file {
          url
          contentType
        }
        fluid(maxWidth: 300, quality: 100) {
          ...GatsbyContentfulFluid
        }
      }
      contentProvidersTitle
      featuredContentProviders {
        file {
          url
          contentType
        }
        fluid(maxWidth: 300, quality: 100) {
          ...GatsbyContentfulFluid
        }
      }
      contentProviders {
        file {
          url
          contentType
        }
        fluid(maxWidth: 300, quality: 100) {
          ...GatsbyContentfulFluid
        }
      }
      learnCalloutCtaLink
      learnCalloutCtaText
      learnCalloutText
      contentPartnersTitle
      contentPartnersSubheader {
        json
      }
    }
  }
`;
